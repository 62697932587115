<template>
  <div class="dd-zy_item_5">
    <h3>方案场景更丰富</h3>
    <p>为多类应用场景提供专业的解决方案</p>
    <ul>
      <li>
        <img src="/static/img/dd_p_icon_1.png"/>
        <div>
          <h2>客户管理解决方案</h2>
          <p>实现客户全周期管理，随时查看客户进度，解决销售难题、完成线上审批、售后全周期管理，降低资料维护成本。</p>
        </div>
      </li>
      <li>
        <img src="/static/img/dd_p_icon_2.png"/>
        <div>
          <h2>生产制造管理解决方案</h2>
          <p>通过生产数智化，实现生产进度和质量的反馈及时性、车间协同层沟通的时效性、销售订单进度的可视化和工序进度的可视化等难题。</p>
        </div>
      </li>
      <li>
        <img src="/static/img/dd_p_icon_3.png"/>
        <div>
          <h2>零售门店管理解决方案</h2>
          <p>通过门店数字化，改变连锁餐饮门店传统管理模式，解决门店经营状况无法实时掌控、采购管理混乱、巡店数据统计难题。</p>
        </div>
      </li>
    </ul>
  </div>
</template>
<script lang="ts">
export default {
  mounted() {

  }
}
</script>
<style lang="less" scoped>
.dd-zy_item_5 {
  padding: 30px 20px;
  color: #383838;

  >h3 {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }

  >p {
    font-size: 16px;
    font-weight: 300;
    margin-top: 15px;
    text-align: center;
    margin-bottom: 30px;
    line-height: 1.8;
  }

  ul{
    li{
      padding: 30px 25px;
      background-color: #f6f8fa;
      border-radius: 10px;
      box-shadow: 0 0 0 1px #e8eaed;
      display: flex;
      align-items: flex-start;
      &:not(:last-child){
        margin-bottom: 15px;
      }
      >img{
        width: 50px;
        max-width: 50px;
        margin-right: 20px;
      }
      h2{
        font-size: 17px;
        font-weight: bold;
        margin-bottom: 10px;
      }
      p{
        font-size: 15px;
        font-weight: 300;
        text-align: justify;
        line-height: 1.8;
      }
    }
  }
}
</style>
