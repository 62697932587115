<template>
  <div class="dd_item" :style="{backgroundColor: bgColor}">
    <h3>{{ title }}</h3>
    <p>{{ desc }}</p>
    <div class="box">
      <img :src="img" />
    </div>
  </div>
</template>
<script lang="ts">
export default {
  props: {
    img: {
      type: String,
    },
    title: {
      type: String,
    },
    desc: {
      type: String,
    },
    bgColor: {
      type: String,
    }

  },
  mounted() {

  }
}
</script>
<style lang="less" scoped>
.dd_item {
  padding: 30px 20px;
  color: #383838;
  background-color: white;

  >h3 {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }

  >p {
    font-size: 16px;
    font-weight: 300;
    margin-top: 20px;
    text-align: justify;
    margin-bottom: 30px;
    line-height: 1.8;
  }

  .box {
    >img {
      display: block;
      border-radius: 10px;
      max-width: 100%;
    }
  }
}
</style>
