<template>
  <div class="dd_cases_container">
    <h3>客户案例</h3>
    <div class="_scroll_container">
      <ul class="_scroll_wapper">
        <li v-for="(item, index) in  list " :key="index" :class="{ outline: cardOutline }"
          :style="{ backgroundColor: bgColor }">
          <h3>{{ item.title }}</h3>
          <img :src="item.img" />
          <p>{{ item.remark }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array
    },
    bgColor: {
      type: String
    },
    cardOutline: {
      type: Boolean,
      default: false
    }
  },
}
</script>
<style lang="less" scoped>
::-webkit-scrollbar {
  display: none;
}

.dd_cases_container {
  padding: 40px 0;

  >h3 {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 35px;
  }

  ._scroll_container {
    overflow: auto;



    ._scroll_wapper {
      display: flex;
      padding: 2px 0;
      &::after {
        content: '';
        display: block;
        width: 5px;
        min-width: 5px;
      }

      >li {
        width: 226px;
        min-width: 226px;
        padding: 15px 15px 20px;
        color: #383838;
        background-color: white;
        margin:0;
        margin-right: 15px;
        border-radius: 10px;


        &.outline {
          box-shadow: 0 0 0 1px #e8eaed;
        }


        &:first-child {
          margin-left: 20px;
        }

        >img {
          width: 100%;
          display: block;
          margin: 10px 0;
          z-index: 2;
          position: relative;
        }

        >h3 {
          font-size: 18px;
          font-weight: bold;
          text-align: center;
          text-transform: uppercase;
        }

        >p {
          font-size: 15px;
          font-weight: 300;
          line-height: 1.8;
          z-index: 2;
          position: relative;
          text-align: justify;
        }
      }
    }
  }

}
</style>