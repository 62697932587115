<template>
  <a class="dd-banner" :href="onLine" target="_blank">
    <img src="/static/img/dd_p_banner.jpg" />
  </a>
  <DDZYItem1 />
  <DDItem img="/static/img/dd_p_img1.jpg" title="智能摘要" desc="进入新群时，智能摘要让你快速了解聊天背景，通过智能助手快速回顾每一段聊天。" bg-color="#e5f6ff" />
  <DDItem img="/static/img/dd_p_img2.png" title="统一存储1TB" desc="存储打通，直播、音视频会议、聊天记录、钉闪会等统一存储，容量管理更方便。" />
  <DDItem img="/static/img/dd_p_img3.jpg" title="高级功能" desc="通过并行节点、业务规则、动态流程、OCR识别、单选数据联动、多条件组合等能力支持企业90%以上的业务流程。"
    bg-color="#e5f6ff" />
  <DDZYItem5 />
  <DDCase style="background-color: #0082fe;color: white;" :list="list" />
</template>
<script>
import { onLine } from "@/data.ts";
import DDZYItem1 from '../../components/dingtalk/DDZYItem1_v2.vue';
import DDItem from '../../components/dingtalk/DDItem_v2.vue';
import DDCase from '../../components/dingtalk/DDCase_v2.vue';
import DDZYItem5 from '../../components/dingtalk/DDZYItem5_v2.vue';
export default {
  components: {
    DDZYItem1,
    DDItem,
    DDCase,
    DDZYItem5
  },
  data() {
    return {
      onLine,
      list: [
        { img: '/static/img/dd_p_img4.jpg', title: '四川德恩精工科技', remark: '德恩精工联合钉钉积极探索“互联网+先进制造业”工业互联网应用新模式，推动传统制造业向现代服务业深度融合。' },
        { img: '/static/img/dd_p_img5.jpg', title: '秦皇岛秦冶重工', remark: '长城汽车&通过在钉钉专业版和宜搭平台的深度开发，秦冶重工搭建了采购业务数字化平台，快速实现了ERP系统功能向下游业务的覆盖。' },
        { img: '/static/img/dd_p_img6.jpg', title: '广州里工实业', remark: '里工实业有限公司是全球智能制造AIoT装备市场的创新者，在不断深化数字化管理的过程中，里工实业也给所有中小企业做了示范。' },
        { img: '/static/img/dd_p_img7.jpg', title: '新疆风能', remark: '新疆风能有限责任公司通过业务数据在钉钉平台上的沉淀，用智能报表完善企业数字化运营状态，提升效率，科学决策。' },
        { img: '/static/img/dd_p_img8.jpg', title: '上海晶准生物医药', remark: '钉钉数字化办公平台给晶准生物带来巨大的助力，帮助公司降低25%内部沟通成本，降低30%左右综合管理成本。' },
        { img: '/static/img/dd_p_img9.jpg', title: '诺尔医疗', remark: '钉钉专业版让业务数据分析更加方便简洁，同时让我们可以积累公司宝贵的过程性资料，沉淀员工智慧。' },
      ]
    }
  }
}
</script>
<style lang="less" scoped>
.dd-banner {
  color: #fff;

  >img {
    width: 100%;
  }

  >h3 {
    font-size: 20px;
    font-weight: bold;
  }

  >p {
    font-size: 15px;
    font-weight: 300;
    line-height: 1.8;
    margin-top: 10px;
  }

  >a {
    line-height: 40px;
    text-align: center;
    display: inline-block;
    height: 40px;
    background: #F23D49;
    border-radius: 3px;
    font-size: 16px;
    font-weight: bold;
    color: #FFFFFF;
    margin-top: 30px;
    border: 0 none;
    width: 115px;
  }
}
</style>
