<template>
  <div class="dd-zy">
    <h3>打造企业数字生产力平台</h3>
    <p>90秒了解钉钉专业版</p>
    <div class="box">
      <img src="/static/img/video_play.png" v-show="!playStatu" @click="playVideo" />
      <video id="professionVideo" src="/static/img/dingtalk_profession.mp4" controls preload v-show="playStatu"></video>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      playStatu: false
    }
  },
  mounted() {
    const video = document.querySelector("#professionVideo");
    if (video) {
      video.addEventListener("pause", this.videoStatu, false)
      video.addEventListener("play", this.videoStatu, false)
    }
  },
  beforeUnmount() {
    const video = document.querySelector("#professionVideo");
    if (video) {
      video.removeEventListener("pause", this.videoStatu, false)
      video.removeEventListener("play", this.videoStatu, false)
    }
  },
  methods: {
    playVideo() {
      const video = document.querySelector("#professionVideo");
      video.play();
      this.playStatu = true;
    },
    videoStatu(e) {
      this.playStatu = e.type == "play";
    }
  },

}
</script>
<style lang="less" scoped>
.dd-zy {
  padding: 30px 20px;
  color: #383838;

  >h3 {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }

  >p {
    font-size: 16px;
    font-weight: 300;
    margin-top: 20px;
    text-align: center;
    margin-bottom: 30px;
  }

  .box {
    border-radius: 10px;
    height: 192px;
    overflow: hidden;

    >video,
    >img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
