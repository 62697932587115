
export default {
  props: {
    img: {
      type: String,
    },
    title: {
      type: String,
    },
    desc: {
      type: String,
    },
    bgColor: {
      type: String,
    }

  },
  mounted() {

  }
}
